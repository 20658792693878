// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShortAddress_shortAddress__16h3M {\n  display: flex;\n  flex-flow: row nowrap;\n  grid-gap: 6px;\n  gap: 6px;\n  align-items: center;\n  color: var(--brand-warm-dark-text);\n}\n\n.ShortAddress_shortAddress__16h3M > div {\n  padding-top: -2px;\n}\n\n.ShortAddress_shortAddress__16h3M span {\n  letter-spacing: 0.2px;\n  font-family: 'PT Root UI';\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/components/ShortAddress/ShortAddress.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,aAAQ;EAAR,QAAQ;EACR,mBAAmB;EACnB,kCAAkC;AACpC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".shortAddress {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 6px;\n  align-items: center;\n  color: var(--brand-warm-dark-text);\n}\n\n.shortAddress > div {\n  padding-top: -2px;\n}\n\n.shortAddress span {\n  letter-spacing: 0.2px;\n  font-family: 'PT Root UI';\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shortAddress": "ShortAddress_shortAddress__16h3M"
};
export default ___CSS_LOADER_EXPORT___;
