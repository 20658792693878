// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileDelegationEvent_scaleIconWrapper__1sALi {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--brand-gray-light-text-translucent);\n  color: var(--brand-warm-light-text);\n  border-radius: 100%;\n  height: 38px;\n  width: 38px;\n  margin-left: 0.4rem;\n}\n\n.MobileDelegationEvent_scaleIcon__3bHM7 {\n  height: 22px;\n  width: 22px;\n}\n\n.MobileDelegationEvent_bold__1Y83q {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/components/profileEvent/event/MobileDelegationEvent/MobileDelegationEvent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,0DAA0D;EAC1D,mCAAmC;EACnC,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".scaleIconWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--brand-gray-light-text-translucent);\n  color: var(--brand-warm-light-text);\n  border-radius: 100%;\n  height: 38px;\n  width: 38px;\n  margin-left: 0.4rem;\n}\n\n.scaleIcon {\n  height: 22px;\n  width: 22px;\n}\n\n.bold {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scaleIconWrapper": "MobileDelegationEvent_scaleIconWrapper__1sALi",
	"scaleIcon": "MobileDelegationEvent_scaleIcon__3bHM7",
	"bold": "MobileDelegationEvent_bold__1Y83q"
};
export default ___CSS_LOADER_EXPORT___;
